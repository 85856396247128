import React, { useState } from 'react';
import FileUpload from './components/FileUpload';
import SummaryTable from './components/SummaryTable';
import './App.css';

const App = () => {
  const [summaryData, setSummaryData] = useState([]); // Ensure it's initialized as an empty array

  const handleFileData = (data) => {
    setSummaryData(data || []); // Fallback to an empty array if data is undefined
  };

  return (
    <div className="App container mt-5">
      {/* Bootstrap Card for VAT Payable Calculator and File Upload */}
      <div className="card mb-4 shadow">
        <div className="card-body">
          <h1 className="card-title">VAT Payable Calculator</h1>
          <p className="card-text">Upload your CSV or Excel file to calculate VAT payable:</p>
          <FileUpload onFileData={handleFileData} />
        </div>
      </div>

      {/* Bootstrap Card for Summary Table */}
      {summaryData && summaryData.length > 0 && (  // Check if summaryData is defined and has content
        <div className="card shadow">
          <div className="card-body">
            <SummaryTable data={summaryData} />
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
