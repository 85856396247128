import React, { useState } from 'react';

const SummaryTable = ({ data }) => {
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  // Function to handle sorting
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Function to sort data based on sortConfig
  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.key) {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (sortConfig.direction === 'ascending') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    }
    return 0;
  });

  // Calculate totals for the applicable columns
  const calculateTotals = () => {
    const totals = {
      totalQty: 0,
      totalItemCost: 0,
      totalItemNetTotal: 0,
      totalVatPayable: 0,
    };

    data.forEach(item => {
      totals.totalQty += item.totalQty;
      totals.totalItemCost += item.totalItemCost;
      totals.totalItemNetTotal += item.totalItemNetTotal;
      totals.totalVatPayable += item.totalVatPayable;
    });

    return totals;
  };

  const totals = calculateTotals();

  return (
    <div>
      <h2 className="mb-4">Summary</h2>
      <table className="table table-hover table-bordered">
        <thead className="thead-dark">
          <tr>
            <th>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => handleSort('itemCode')}
              >
                Item Code {sortConfig.key === 'itemCode' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
              </button>
            </th>
            <th>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => handleSort('itemDescription')}
              >
                Item Description {sortConfig.key === 'itemDescription' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
              </button>
            </th>
            <th className="text-right">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => handleSort('totalQty')}
              >
                Total Quantity {sortConfig.key === 'totalQty' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
              </button>
            </th>
            <th className="text-right">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => handleSort('totalItemCost')}
              >
                Total Item Cost {sortConfig.key === 'totalItemCost' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
              </button>
            </th>
            <th className="text-right">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => handleSort('totalItemNetTotal')}
              >
                Total Item Net Total {sortConfig.key === 'totalItemNetTotal' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
              </button>
            </th>
            <th className="text-right">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => handleSort('totalVatPayable')}
              >
                Total VAT Payable {sortConfig.key === 'totalVatPayable' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index}>
              <td>{item.itemCode}</td>
              <td>{item.itemDescription}</td>
              <td className="text-right">{item.totalQty.toFixed(2)}</td>
              <td className="text-right">{item.totalItemCost.toFixed(2)}</td>
              <td className="text-right">{item.totalItemNetTotal.toFixed(2)}</td>
              <td className="text-right">{item.totalVatPayable.toFixed(2)}</td>
            </tr>
          ))}

          {/* Add a row for totals at the end */}
          <tr>
            <td colSpan="2"><strong>Totals</strong></td>
            <td className="text-right"><strong>{totals.totalQty.toFixed(2)}</strong></td>
            <td className="text-right"><strong>{totals.totalItemCost.toFixed(2)}</strong></td>
            <td className="text-right"><strong>{totals.totalItemNetTotal.toFixed(2)}</strong></td>
            <td className="text-right"><strong>{totals.totalVatPayable.toFixed(2)}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SummaryTable;
