import React from 'react';
import * as XLSX from 'xlsx';

const FileUpload = ({ onFileData }) => {
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
      console.log("File content:", rows);  // Log to verify file content
      processData(rows);
    };

    reader.readAsArrayBuffer(file);
  };

  const processData = (rows) => {
    const headers = rows[0];
    const data = rows.slice(1).map((row) => {
      const item = {};
      headers.forEach((header, index) => {
        item[header] = row[index];
      });
      return item;
    });

    console.log("Processed Data:", data); // Log to verify processed data
    const summaryData = summarizeData(data);
    onFileData(summaryData);
  };

  const calculateVATPayable = (itemNetTotal, itemCost, vatRate) => {
    const inputVAT = itemCost * vatRate;
    const outputVAT = itemNetTotal * vatRate;
    return outputVAT - inputVAT;
  };

  const summarizeData = (data) => {
    const summary = {};

    data.forEach((item) => {
      const itemCode = item['Item Code'];
      const qty = parseFloat(item['QTY']);
      const itemCost = parseFloat(item['Item Cost']);
      const itemNetTotal = parseFloat(item['Item Net Total']);
      const vatPayable = calculateVATPayable(itemNetTotal, itemCost, 0.18);

      if (!summary[itemCode]) {
        summary[itemCode] = {
          itemDescription: item['Item Description'],
          totalQty: 0,
          totalItemCost: 0,
          totalItemNetTotal: 0,
          totalVatPayable: 0,
        };
      }

      summary[itemCode].totalQty += qty;
      summary[itemCode].totalItemCost += itemCost;
      summary[itemCode].totalItemNetTotal += itemNetTotal;
      summary[itemCode].totalVatPayable += vatPayable;
    });

    console.log("Summary Data:", summary); // Log to verify summary data
    return Object.entries(summary).map(([itemCode, values]) => ({
      itemCode,
      ...values,
    }));
  };

  return (
    <div>
      <input type="file" accept=".xlsx, .csv" onChange={handleFileUpload} />
    </div>
  );
};

export default FileUpload;
